import React from "react";
import Content from '../views/RepairShop/Main';

export default ({location}) => (
  <Content location={location} seo={{
      lang: 'en',
      title: "Vehicle Care - a link between car workshops and customers",
      description: 'Vehicle Care - a unique connected car solution for car workshops, enabloíng them to stay informed of each connected vehicle and be closer to customers.',
      meta: [{
        name: 'keywords',
        content: 'Car workshops'
      }]}}/>
);